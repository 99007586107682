import React from 'react';
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../layout"
import SEO from "../seo";
import { graphql } from 'gatsby';



export default function PageLayout({ children, data }) {
    let { mdx: { frontmatter, body, html } } = data;
    return (
        <>
            <Layout>
                <SEO title={frontmatter.title} />
                <div className=" grid grid-cols-12 ">
                    <div className="col-span-12 sm:col-start-2 sm:col-end-12 md:col-start-3 lg:col-end-11 px-5">


                        <h1 className="text-purple-600 text-3xl sm:text-4xl md:text-6xl font-bold mb-16 mt-8">
                            {frontmatter.title}
                        </h1>
                        <div className="prose prose-lg md:prose-xl max-w-none mb-20">

                            <MDXRenderer >{body}</MDXRenderer>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}


export const data = graphql`
query($slug:String!){
    mdx(fields:{slug:{eq:$slug}}) {
      frontmatter {
        title
      }
      body

    }
  }
`